
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import {
  addPayment,
  addPaymentRefundItemRequest,
  searchPayment,
} from "@/api/payment.api";
import { useStore } from "vuex";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useFilters from "../common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  isLoaded: boolean;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    boolList: { code?: boolean; name: string }[];
    paymentTypes: StatusCode[];
    adjustmentReasonCodes: StatusCode[];
    transactionTypes: StatusCode[];
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "PatientTransactions",
  props: ["patientId"],
  components: { PaginationUi },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
      cleanHeaderFilter,
      getFilter,
    } = useFilters();

    orderList.value = [
      {
        name: "Entry Date",
        key: "entryDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Transaction ID",
        key: "paymentId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payer",
        key: "payee",
        isFilter: true,
        keyword: null,
        formType: "payee",
        IsAdvancedOrderBy: true,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: null,
        formType: "paymentType",
      },
      {
        name: "Ref #",
        key: "reference",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Unapplied Credit",
        key: "unappliedCredit",
        isFilter: true,
        keyword: null,
        formType: "number",
      },
      //item.unappliedCredit
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      orderBy: "paymentId",
      IsAdvancedOrderBy: true,
      isDecr: false,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: undefined, name: "All" },
        ],
        paymentTypes: [],
        transactionTypes: [],
        adjustmentReasonCodes: [],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "paymentId";
      data.IsAdvancedOrderBy = true;
      data.isDecr = false;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      data.dict.adjustmentReasonCodes = store.getters.allAdjustmentReasonCodes;
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;

      await getAll();
      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getDescription(item) {
      const type = data.dict.paymentTypes.find((el) => {
        return el.code == item.paymentType;
      });
      if (type) return type.description;
      return item.paymentType;
    }

    async function getAll() {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        PatientId: props.patientId,
        UnAppliedCreditOnly: true,
      };

      const res = await searchPayment(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function selectItem(item) {
      //router.push({ path: "/patientPayment/" + item.id + "/", query: { patientId: props.patientId } });
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function applyPayment(item) {
      router.push({
        path: "/patientPayment/" + item.id + "/",
        query: { patientId: item.patientId },
      });
    }

    async function autoRefund(item) {
      Swal.fire({
        title: "Are you sure you want to Refund Credits?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          let refund = {
            id: "",
            type: 2,
            paymentType: 2,
            adjustmentReason: 0,
            entryDate: getUTCnow().toISOString(),
            paymentDate: getUTCnow().toISOString(),
            accountingDate: getUTCnow().toISOString(),
            unallocatedAmount: 0,
            totalAmount: item.unallocatedAmount,
            patientId: props.patientId,
            unappliedCredit: null,
          };

          const res = (await addPayment(refund)) as any;
          const paymentId = res.data;
          const itemToPost = {
            RefundAmount: item.unallocatedAmount,
            Id: item.id,
            PaymentId: paymentId,
          };
          const postRes = await addPaymentRefundItemRequest({
            entryDate: getUTCnow().toISOString(),
            paymentId: paymentId,
            items: [itemToPost],
          });

          await getAll();
          ctx.emit("refreshPatient");
          Swal.fire("Ok!", item.paymentId + " has been refunded", "success");
        }
      });
    }

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    return {
      applyPayment,
      organizationId,
      data,
      selectItem,
      orderList,
      getDescription,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      autoRefund,
    };
  },
});
